/**
 * Bootstrap-Markdown.sass
 *
 * @author Taufan Aditya @taufanaditya
 * @copyright 2013 Taufan Aditya
 */

.md-editor {
  display: block;
  border: 1px solid $table-border-color;

  > .md-header, .md-footer {
    display: block;
    padding: 6px 4px;
    background: $panel-default-heading-bg;
    margin:0px;
  }

  > .md-header >:first-child {
    margin-left:0px;
  }

  > .md-preview {
    background: $panel-bg;
    border-top: 1px dashed $table-border-color;
    border-bottom: 1px dashed $table-border-color;
    min-height: 10px;
    padding:10px;
    overflow: scroll;
  }

  > textarea {
    font-family: $font-family-monospace;
    font-size: $font-size-base;
    outline: 0;
    outline: thin dotted  \9; /* IE6-9 */
    margin: 0;
    display: block;
    padding: 0;
    width: 100%;
    border: 0;
    padding:10px;
    border-top: 1px dashed $table-border-color;
    border-bottom: 1px dashed $table-border-color;
    border-radius: 0;
    box-shadow: none;
    background: $input-bg-disabled;
    &:focus {
      box-shadow: none;
      background: $input-bg;
    }
  }

  // Hover state
  $color: $input-border-focus;
  $color-rgba: rgba(red($color), green($color), blue($color), .6);
  &.active {
    border-color: $color;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px $color-rgba;
  }
}

/* adjustments */
.md-fullscreen-controls, .md-control-fullscreen {
  display:none !important;
}