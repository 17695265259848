/*
Version: 3.4.5 Timestamp: Mon Nov  4 08:22:42 PST 2013
*/
.select2-container {
  margin: 0;
  position: relative;
  display: inline-block;
  /* inline-block for ie7 */
  zoom: 1;
  *display: inline;
  vertical-align: middle;
  .select2-choice {
    display: block;
    height: 32px;
    padding: 0 0 0 8px;
    overflow: hidden;
    position: relative;

    border: 1px solid #ccc;
    white-space: nowrap;
    line-height: 32px;
    color: #444;
    text-decoration: none;


    background-clip: padding-box;

    -webkit-touch-callout: none;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;

    background-color: #fff;
    abbr {
      display: none;
      width: 12px;
      height: 12px;
      position: absolute;
      right: 32px;
      top: 8px;

      font-size: 15px;
      text-decoration: none;

      border: 0;
      /*background: url("#{$base-url}/select2.png") right top no-repeat;*/
      cursor: pointer;
      outline: 0;
      &:hover {
          /*background-position: right -11px;*/
          cursor: pointer;
      }
    }
    > .select2-chosen {
      margin-right: 26px;
      display: block;
      overflow: hidden;

      white-space: nowrap;

      text-overflow: ellipsis;
    }
    .select2-arrow {
      display: inline-block;
      width: 34px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;

      border-left: 1px solid #ccc;

      background-clip: padding-box;

      background: #eee;
      b {
        display: block;
        width: 100%;
        height: 100%;
        display: inline-block;
        font-family: FontAwesome;
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: relative;
        font-size:14px;
        &:before {
          content: "\f107";
          width: 100%;
          height: 100%;
          text-align:center;
          display:block;
        }
      }
    }
  }
  &.select2-drop-above .select2-choice {
    border-bottom-color: #ccc;
  }
  &.select2-allowclear .select2-choice {
    abbr { display: inline-block; }
    .select2-chosen { margin-right: 42px; }
  }
}

.select2-container, .select2-drop, .select2-search, .select2-search input {
  /*
    Force border-box so that % widths fit the parent
    container without overlap because of margin/padding.

    More Info : http://www.quirksmode.org/css/box.html
  */
  -webkit-box-sizing: border-box; /* webkit */
     -moz-box-sizing: border-box; /* firefox */
          box-sizing: border-box; /* css3 */
}

.select2-drop-mask {
  border: 0;
  margin: 0;
  padding: 0;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 9998;
  /* styles required for IE to work */
  background: #fff;
  filter: alpha(opacity=0);
}

.select2-drop {
  margin-top:-1px;

  width: 100%;
  position: absolute;
  z-index: 9999;
  top: 100%;

  background: #fff;
  color: #000;
  border: 1px solid #ccc;
  border-top: 0;

  -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, .15);
          box-shadow: 0 4px 5px rgba(0, 0, 0, .15);
}

.select2-drop-auto-width {
  border-top: 1px solid #ccc;
  width: auto;
}

.select2-drop-auto-width .select2-search {
  padding-top: 4px;
}

.select2-drop.select2-drop-above {
  margin-bottom: 0px;
  border-top: 1px solid #5D98CC;
  border-top-width: 3px;
  border-bottom: 0;
  -webkit-box-shadow: 0 -4px 5px rgba(0,0,0,.15);
  box-shadow: 0 -4px 5px rgba(0,0,0,.15);
}

.select2-drop-active {
  border: 1px solid #5D98CC;
  border-top: none;
  border-bottom-width:3px;
}

.select2-drop.select2-drop-above.select2-drop-active {
  border-top-width: 3px;
}

.select2-search {
  display: inline-block;
  width: 100%;
  min-height: 26px;
  margin: 0;
  padding-left: 4px;
  padding-right: 4px;

  position: relative;
  z-index: 10000;

  white-space: nowrap;
  input {
    width: 100%;
    height: auto !important;
    min-height: 29px;
    padding: 6px 20px 5px 10px;
    margin: 0;

    outline: 0;
    font-family: sans-serif;
    font-size: 1em;

    border: 1px solid #aaa;

    -webkit-box-shadow: none;
            box-shadow: none;

    background: #fff;
    background: -webkit-gradient(linear, left bottom, left top, color-stop(0.85, #fff), color-stop(0.99, #eee));
    background: -webkit-linear-gradient(center bottom, #fff 85%, #eee 99%);
    background: -moz-linear-gradient(center bottom, #fff 85%, #eee 99%);
    background: linear-gradient(top, #fff 85%, #eee 99%);
  }
  &:before {
  	display: inline-block;
  	font-family: FontAwesome;
  	font-style: normal;
  	font-weight: normal;
  	line-height: 1;
  	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
  	position:absolute;
  	content: "\f002";
  	top: 25%;
  	right: 10px;
  	color: #686868 !important;
  }

  input.select2-active {
    background: #fff url("#{$base-url}/select2-spinner.gif") no-repeat 100%;
    background: url("#{$base-url}/select2-spinner.gif") no-repeat 100%, -webkit-gradient(linear, left bottom, left top, color-stop(0.85, #fff), color-stop(0.99, #eee));
    background: url("#{$base-url}/select2-spinner.gif") no-repeat 100%, -webkit-linear-gradient(center bottom, #fff 85%, #eee 99%);
    background: url("#{$base-url}/select2-spinner.gif") no-repeat 100%, -moz-linear-gradient(center bottom, #fff 85%, #eee 99%);
    background: url("#{$base-url}/select2-spinner.gif") no-repeat 100%, linear-gradient(top, #fff 85%, #eee 99%);
    background-position: right 20px center;
  }
}

.select2-drop.select2-drop-above .select2-search input { margin-top: 4px; }
.select2-drop.select2-drop-above .select2-search:before { top: 34%; }

.select2-container-active .select2-choice, .select2-container-active .select2-choices {
  border: 1px solid #5D98CC;
  outline: none;
}

.select2-dropdown-open .select2-choice {
  border-bottom-color: transparent;
  -webkit-box-shadow: 0 1px 0 #fff inset;
          box-shadow: 0 1px 0 #fff inset;

  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  background-color: #fff;
}

.select2-dropdown-open.select2-drop-above .select2-choice, .select2-dropdown-open.select2-drop-above .select2-choices {
  border: 1px solid #5D98CC;
  border-top-color: transparent;
}

.select2-dropdown-open .select2-choice .select2-arrow {
  background: transparent;
  border-left: none;
  filter: none;
}
.select2-dropdown-open .select2-choice .select2-arrow b {
  background-position: -18px 1px;
}

/* results */
.select2-results {
  max-height: 200px;
  padding: 0 0 0 4px;
  margin: 4px 4px 4px 0;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.select2-results ul.select2-result-sub {
  margin: 0;
  padding-left: 0;
}

.select2-results ul.select2-result-sub > li .select2-result-label { padding-left: 20px }
.select2-results ul.select2-result-sub ul.select2-result-sub > li .select2-result-label { padding-left: 40px }
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label { padding-left: 60px }
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label { padding-left: 80px }
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label { padding-left: 100px }
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label { padding-left: 110px }
.select2-results ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub ul.select2-result-sub > li .select2-result-label { padding-left: 120px }

.select2-results li {
  list-style: none;
  display: list-item;
  background-image: none;
}

.select2-results li.select2-result-with-children > .select2-result-label {
  font-weight: bold;
}

.select2-results .select2-result-label {
  padding: 3px 7px 4px;
  margin: 0;
  cursor: pointer;

  min-height: 1em;

  -webkit-touch-callout: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.select2-results .select2-highlighted {
  background: $blueStandard;
  color: #fff;
}

.select2-results li em {
  background: #feffde;
  font-style: normal;
}

.select2-results .select2-highlighted em {
  background: transparent;
}

.select2-results .select2-highlighted ul {
  background: #fff;
  color: #000;
}


.select2-results .select2-no-results,
.select2-results .select2-searching,
.select2-results .select2-selection-limit {
  background: #f4f4f4;
  display: list-item;
  padding:3px 5px;
}

.select2-no-results {
  padding-left:20px;
}

.select2-no-results:before {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f05a";
  margin-right:5px;
}

/*
disabled look for disabled choices in the results dropdown
*/
.select2-results .select2-disabled.select2-highlighted {
  color: #666;
  background: #f4f4f4;
  display: list-item;
  cursor: default;
}
.select2-results .select2-disabled {
    background: #f4f4f4;
    display: list-item;
    cursor: default;
}

.select2-results .select2-selected {
  display: none;
}

.select2-more-results.select2-active {
  background: #f4f4f4 url("#{$base-url}/select2-spinner.gif") no-repeat 100%;
}

.select2-more-results {
  background: #f4f4f4;
  display: list-item;
}

/* disabled styles */

.select2-container.select2-container-disabled .select2-choice {
  background-color: $input-bg-disabled;
  background-image: none;
  border: 1px solid #ddd;
  cursor: default;
}

.select2-container.select2-container-disabled .select2-choice .select2-arrow {
  background-color: $input-bg-disabled;
  background-image: none;
  border-left: 0;
}

.select2-container.select2-container-disabled .select2-choice abbr {
  display: none;
}


/* multiselect */

.select2-container-multi .select2-choices {
  height: auto !important;
  height: 1%;
  margin: 0;
  padding: 0;
  position: relative;

  border: 1px solid #ccc;
  cursor: text;
  overflow: hidden;

  background-color: #fff;
}

.select2-locked {
padding: 3px 5px 3px 5px !important;
}

.select2-container-multi .select2-choices {
  min-height: 26px;
}

.select2-container-multi.select2-container-active .select2-choices {
  border: 1px solid #5D98CC;
  outline: none;


}
.select2-container-multi .select2-choices li {
  float: left;
  list-style: none;
}
.select2-container-multi .select2-choices .select2-search-field {
  margin: 0;
  padding: 0;
  white-space: nowrap;
}

.select2-container-multi .select2-choices .select2-search-field input {
  padding: 5px;
  margin: 1px 0;

  font-family: sans-serif;
  font-size: 100%;
  color: #666;
  outline: 0;
  border: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  background: transparent !important;
}

.select2-container-multi .select2-choices .select2-search-field input.select2-active {
  background: #fff url("#{$base-url}/select2-spinner.gif") no-repeat 100% !important;
}

.select2-default {
  color: #999 !important;
}

.select2-container-multi .select2-choices .select2-search-choice {
  padding: 1px 28px 1px 8px;
  margin: 4px 0 3px 5px;
  position: relative;
  line-height: 18px;
  color: #fff;
  cursor: default;
  border: 1px solid darken($blueStandard, 7%);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: $blueStandard;
}
.select2-container-multi .select2-choices .select2-search-choice .select2-chosen {
  cursor: default;
}
.select2-container-multi .select2-choices .select2-search-choice-focus {
  opacity:.8;
}

.select2-search-choice-close {
  display: block;
  min-width: 21px;
  min-height: 20px;
  position: absolute;
  right: 3px;
  top: 3px;
  margin: 0;
  padding: 0;
  font-size: 15px;
  line-height: 12px;
  text-decoration: none!important;

  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* CSS for allow-clear button  */
  &:before {
      color: gray;
      content: "\f00d";
  }
}

/*
.select2-search-choice-close:before {
  color: gray;
  content: "\f00d";
}
*/

.select2-container-multi .select2-search-choice-close {
  display: block;
  top: 0;
  right: 0;
  padding: 3px 4px 3px 6px;

  /* CSS for 'multiple' allow-clear button  */
  &:before {
      color: white;
      content: "\f00d";
  }
}
.select2-container-multi .select2-search-choice-close:hover{
  background:rgba(0,0,0,.3);
}
/*
.select2-container-multi .select2-choices .select2-search-choice .select2-search-choice-close:hover {
background-position: right -11px;
}
.select2-container-multi .select2-choices .select2-search-choice-focus .select2-search-choice-close {
  background-position: right -11px;
}*/

/* disabled styles */
.select2-container-multi.select2-container-disabled .select2-choices {
  background-color: #f4f4f4;
  background-image: none;
  border: 1px solid #ddd;
  cursor: default;
}

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
  padding: 3px 5px;
  border: 1px solid lighten($blueStandard, 10%);
  background-image: none;
  background-color: lighten($blueStandard, 25%);
  cursor:not-allowed;
}

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice .select2-search-choice-close {    display: none;
  background: none;
}
/* end multiselect */


.select2-result-selectable .select2-match,
.select2-result-unselectable .select2-match {
  text-decoration: underline;
}

.select2-offscreen, .select2-offscreen:focus {
  clip: rect(0 0 0 0) !important;
  width: 1px !important;
  height: 1px !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: absolute !important;
  outline: 0 !important;
  left: 0px !important;
  top: 0px !important;
}

.select2-display-none {
  display: none;
}

.select2-measure-scrollbar {
  position: absolute;
  top: -10000px;
  left: -10000px;
  width: 100px;
  height: 100px;
  overflow: scroll;
}

/*
 * ADJUSTMENTS
 */

.has-error .select2-choice,
.has-error .select2-container .select2-choice .select2-arrow,
.has-error .select2-container-multi .select2-choices {
  border-color: $state-danger-border;
}

.has-error .select2-container .select2-choice .select2-arrow {
  background: $state-danger-bg;
  color: $state-danger-border;
}

.has-error .select2-dropdown-open .select2-choice {
  border-color: #5D98CC !important;
}

.select2-drop.select2-drop-above.select2-drop-active {
    margin-top:2px;
}

.editable-input > .select2-container {
  min-width: 200px;
}
