/*!
 * FullCalendar v2.0.2 Stylesheet
 * Docs & License: http://arshaw.com/fullcalendar/
 * (c) 2013 Adam Shaw
 */

.fc {
  direction: ltr;
  text-align: left;
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
}

html .fc {
  font-size: 1em;
}

.fc {
  table {
    font-size: 1em;
  }
  td, th {
    padding: 0;
    vertical-align: top;
  }
}

/* Header
------------------------------------------------------------------------*/

.fc-header td {
  white-space: nowrap;
}

.fc-header-left {
  width: 25%;
  text-align: left;
}

.fc-header-center {
  text-align: center;
}

.fc-header-right {
  width: 25%;
  text-align: right;
}

.fc-header-title {
  display: inline-block;
  vertical-align: top;
  h2 {
    margin-top: 0;
    white-space: nowrap;
  }
}

.fc .fc-header-space {
  padding-left: 10px;
}

.fc-header {
  .fc-button {
    margin-bottom: 1em;
    vertical-align: top;
    margin-right: -1px;
  }
  .fc-corner-right, .ui-corner-right {
    /* theme */
    margin-right: 0;
    /* back to normal */
  }
  .fc-state-hover, .ui-state-hover {
    z-index: 2;
  }
  .fc-state-down {
    z-index: 3;
  }
  .fc-state-active, .ui-state-active {
    z-index: 4;
  }
}

/* buttons edges butting together */

/* button layering (for border precedence) */

/* Content
------------------------------------------------------------------------*/

.fc-content {
  position: relative;
  z-index: 1;
  /* scopes all other z-index's to be inside this container */
  clear: both;
  zoom: 1;
  /* for IE7, gives accurate coordinates for [un]freezeContentHeight */
}

.fc-view {
  position: relative;
  width: 100%;
  overflow: hidden;
}

/* Cell Styles
------------------------------------------------------------------------*/

.fc-widget-header, .fc-widget-content {
  /* <td>, usually */
  border: 1px solid #ddd;
}

.fc-state-highlight {
  /* <td> today cell */
  /* TODO: add .fc-today to <th> */
  background: #fcf8e3;
}

.fc-cell-overlay {
  /* semi-transparent rectangle while dragging */
  background: #bce8f1;
  opacity: .3;
  filter: alpha(opacity = 30);
  /* for IE */
}

/* Buttons
------------------------------------------------------------------------*/

.fc-button {
  position: relative;
  display: inline-block;
  padding: 0 .6em;
  overflow: hidden;
  height: 1.9em;
  line-height: 1.9em;
  white-space: nowrap;
  cursor: pointer;
}

.fc-state-default {
  /* non-theme */
  border: 1px solid;
  &.fc-corner-left {
    /* non-theme */
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &.fc-corner-right {
    /* non-theme */
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

/*
  Our default prev/next buttons use HTML entities like &lsaquo; &rsaquo; &laquo; &raquo;
  and we'll try to make them look good cross-browser.
*/

.fc-button .fc-icon {
  margin: 0 .1em;
  font-size: 2em;
  font-family: "Courier New", Courier, monospace;
  vertical-align: baseline;
  /* for IE7 */
}

.fc-icon-left-single-arrow:after {
  content: "\02039";
  font-weight: bold;
}

.fc-icon-right-single-arrow:after {
  content: "\0203A";
  font-weight: bold;
}

.fc-icon-left-double-arrow:after {
  content: "\000AB";
}

.fc-icon-right-double-arrow:after {
  content: "\000BB";
}

/* icon (for jquery ui) */

.fc-button .ui-icon {
  position: relative;
  top: 50%;
  float: left;
  margin-top: -8px;
  /* we know jqui icons are always 16px tall */
}

/*
  button states
  borrowed from twitter bootstrap (http://twitter.github.com/bootstrap/)
*/

.fc-state-default {
  background-color: #f5f5f5;
  background-image: -moz-linear-gradient(top, white, #e6e6e6);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(white), to(#e6e6e6));
  background-image: -webkit-linear-gradient(top, white, #e6e6e6);
  background-image: -o-linear-gradient(top, white, #e6e6e6);
  background-image: linear-gradient(to bottom, white, #e6e6e6);
  background-repeat: repeat-x;
  border-color: #e6e6e6 #e6e6e6 #bfbfbf;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  color: #333;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
}

.fc-state-hover, .fc-state-down, .fc-state-active, .fc-state-disabled {
  color: #333333;
  background-color: #e6e6e6;
}

.fc-state-hover {
  color: #333333;
  text-decoration: none;
  background-position: 0 -15px;
  -webkit-transition: background-position 0.1s linear;
  -moz-transition: background-position 0.1s linear;
  -o-transition: background-position 0.1s linear;
  transition: background-position 0.1s linear;
}

.fc-state-down, .fc-state-active {
  background-color: #cccccc;
  background-image: none;
  outline: 0;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
}

.fc-state-disabled {
  cursor: default;
  background-image: none;
  opacity: 0.65;
  filter: alpha(opacity = 65);
  box-shadow: none;
}

/* Global Event Styles
------------------------------------------------------------------------*/

.fc-event-container > {
  * {
    z-index: 8;
  }
  .ui-draggable-dragging, .ui-resizable-resizing {
    z-index: 9;
  }
}

.fc-event {
  border: 1px solid #3a87ad;
  /* default BORDER color */
  background-color: #3a87ad;
  /* default BACKGROUND color */
  color: #fff;
  /* default TEXT color */
  font-size: .85em;
  cursor: default;
}

a.fc-event {
  text-decoration: none;
  cursor: pointer;
}

.fc-event-draggable {
  cursor: pointer;
}

.fc-rtl .fc-event {
  text-align: right;
}

.fc-event-inner {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.fc-event-time, .fc-event-title {
  padding: 0 1px;
}

.fc .ui-resizable-handle {
  display: block;
  position: absolute;
  z-index: 99999;
  overflow: hidden;
  /* hacky spaces (IE6/7) */
  font-size: 300%;
  /* */
  line-height: 50%;
  /* */
}

/* Horizontal Events
------------------------------------------------------------------------*/

.fc-event-hori {
  border-width: 1px 0;
  margin-bottom: 1px;
}

.fc-ltr .fc-event-hori.fc-event-start, .fc-rtl .fc-event-hori.fc-event-end {
  border-left-width: 1px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.fc-ltr .fc-event-hori.fc-event-end, .fc-rtl .fc-event-hori.fc-event-start {
  border-right-width: 1px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

/* resizable */

.fc-event-hori {
  .ui-resizable-e {
    top: 0           !important;
    /* importants override pre jquery ui 1.7 styles */
    right: -3px !important;
    width: 7px       !important;
    height: 100%     !important;
    cursor: e-resize;
  }
  .ui-resizable-w {
    top: 0           !important;
    left: -3px !important;
    width: 7px       !important;
    height: 100%     !important;
    cursor: w-resize;
  }
  .ui-resizable-handle {
    _padding-bottom: 14px;
    /* IE6 had 0 height */
  }
}

/* Reusable Separate-border Table
------------------------------------------------------------*/

table.fc-border-separate {
  border-collapse: separate;
}

.fc-border-separate {
  th, td {
    border-width: 1px 0 0 1px;
  }
  th.fc-last, td.fc-last {
    border-right-width: 1px;
  }
  tr.fc-last {
    th, td {
      border-bottom-width: 1px;
    }
  }
  tbody tr.fc-first {
    td, th {
      border-top-width: 0;
    }
  }
}

/* Month View, Basic Week View, Basic Day View
------------------------------------------------------------------------*/

.fc-grid th {
  text-align: center;
}

.fc .fc-week-number {
  width: 22px;
  text-align: center;
  div {
    padding: 0 2px;
  }
}

.fc-grid {
  .fc-day-number {
    float: right;
    padding: 0 2px;
  }
  .fc-other-month .fc-day-number {
    opacity: 0.3;
    filter: alpha(opacity = 30);
    /* for IE */
    /* opacity with small font can sometimes look too faded
       might want to set the 'color' property instead
       making day-numbers bold also fixes the problem */
  }
  .fc-day-content {
    clear: both;
    padding: 2px 2px 1px;
    /* distance between events and day edges */
  }
  .fc-event-time {
    font-weight: bold;
  }
}

/* event styles */

/* right-to-left */

.fc-rtl .fc-grid {
  .fc-day-number {
    float: left;
  }
  .fc-event-time {
    float: right;
  }
}

/* Agenda Week View, Agenda Day View
------------------------------------------------------------------------*/

.fc-agenda table {
  border-collapse: separate;
}

.fc-agenda-days th {
  text-align: center;
}

.fc-agenda .fc-agenda-axis {
  width: 50px;
  padding: 0 4px;
  vertical-align: middle;
  text-align: right;
  font-weight: normal;
}

.fc-agenda-slots .fc-agenda-axis {
  white-space: nowrap;
}

.fc-agenda {
  .fc-week-number {
    font-weight: bold;
  }
  .fc-day-content {
    padding: 2px 2px 1px;
  }
}

/* make axis border take precedence */

.fc-agenda-days {
  .fc-agenda-axis {
    border-right-width: 1px;
  }
  .fc-col0 {
    border-left-width: 0;
  }
}

/* all-day area */

.fc-agenda-allday {
  th {
    border-width: 0 1px;
  }
  .fc-day-content {
    min-height: 34px;
    /* TODO: doesnt work well in quirksmode */
    _height: 34px;
  }
}

/* divider (between all-day and slots) */

.fc-agenda-divider-inner {
  height: 2px;
  overflow: hidden;
}

.fc-widget-header .fc-agenda-divider-inner {
  background: #eee;
}

/* slot rows */

.fc-agenda-slots {
  th {
    border-width: 1px 1px 0;
  }
  td {
    border-width: 1px 0 0;
    background: none;
    div {
      height: 20px;
    }
  }
  tr {
    &.fc-slot0 {
      th, td {
        border-top-width: 0;
      }
    }
    &.fc-minor {
      th, td {
        border-top-style: dotted;
      }
      th.ui-widget-header {
        *border-top-style: solid;
        /* doesn't work with background in IE6/7 */
      }
    }
  }
}

/* Vertical Events
------------------------------------------------------------------------*/

.fc-event-vert {
  border-width: 0 1px;
  &.fc-event-start {
    border-top-width: 1px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  &.fc-event-end {
    border-bottom-width: 1px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .fc-event-time {
    white-space: nowrap;
    font-size: 10px;
  }
  .fc-event-inner {
    position: relative;
    z-index: 2;
  }
  .fc-event-bg {
    /* makes the event lighter w/ a semi-transparent overlay  */
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: .25;
    filter: alpha(opacity = 25);
  }
}

.fc .ui-draggable-dragging .fc-event-bg, .fc-select-helper .fc-event-bg {
  display: none\9;
  /* for IE6/7/8. nested opacity filters while dragging don't work */
}

/* resizable */

.fc-event-vert .ui-resizable-s {
  bottom: 0        !important;
  /* importants override pre jquery ui 1.7 styles */
  width: 100%      !important;
  height: 8px      !important;
  overflow: hidden !important;
  line-height: 8px !important;
  font-size: 11px  !important;
  font-family: monospace;
  text-align: center;
  cursor: s-resize;
}

.fc-agenda .ui-resizable-resizing {
  /* TODO: better selector */
  _overflow: hidden;
}

/*
 * CUSTOM
 */

.fc-event-title .fa {
  font-size: 14px;
  margin-top: 4px;
  margin-right: 4px;
}

.fc-grid .fc-event-time {
  display: block;
}

.fc-event {
  border: none !important;
  border-right:none;
  border-left: 6px solid rgba(0,0,0,.15) !important;
  padding-left: 2px;
}

.fc-event.fc-event-draggable {
  cursor: move;
}
  
.fc-ltr .fc-event-hori.fc-event-end, 
.fc-rtl .fc-event-hori.fc-event-start,
.fc-event-vert.fc-event-end {
  border-right-width: 0px;
  border-radius: 0px;
} 

.fc-ltr .fc-event-hori.fc-event-end:active, 
.fc-rtl .fc-event-hori.fc-event-start:active,
.fc-event-vert.fc-event-end:active {
  opacity:.8;
}
