/*
 * ANIMATED
 */
/* Navigation icon first level 
.desktop-detected nav > ul > li > a:hover i {
	-webkit-animation-name: wobble;
	-moz-animation-name: wobble;
	-o-animation-name: wobble;
	animation-name: wobble;
}
.desktop-detected nav > ul > li > a:hover i {
	-webkit-animation-duration: .7s;
	-moz-animation-duration: .7s;
	-o-animation-duration: .7s;
	animation-duration: .7s;
	-webkit-animation-fill-mode: both;
	-moz-animation-fill-mode: both;
	-o-animation-fill-mode: both;
	animation-fill-mode: both;
}*/

/* All dropdowns */
.open > .dropdown-menu {
	-webkit-animation-name: $animation-type;
	-moz-animation-name: $animation-type;
	-o-animation-name: $animation-type;
	animation-name: $animation-type;
}

.open > .dropdown-menu {
	-webkit-animation-duration: .4s;
	-moz-animation-duration: .4s;
	-o-animation-duration: .4s;
	animation-duration: .4s;
	-webkit-animation-fill-mode: both;
	-moz-animation-fill-mode: both;
	-o-animation-fill-mode: both;
	animation-fill-mode: both;
}

.active + .ajax-dropdown {
	-webkit-animation-name: $animation-ajax-dropdown;
	-moz-animation-name: $animation-ajax-dropdown;
	-o-animation-name: $animation-ajax-dropdown;
	animation-name: $animation-ajax-dropdown;
	-webkit-animation-duration: .7s;
	-moz-animation-duration: .7s;
	-o-animation-duration: .7s;
	animation-duration: .7s;
	-webkit-animation-fill-mode: both;
	-moz-animation-fill-mode: both;
	-o-animation-fill-mode: both;
	animation-fill-mode: both;
}

