.note-editor {
  position: relative;
  border: 1px solid #a9a9a9;
  .note-dropzone {
    position: absolute;
    z-index: 1;
    display: none;
    color: #87cefa;
    background-color: white;
    border: 2px dashed #87cefa;
    opacity: .95;
    pointer-event: none;
    .note-dropzone-message {
      display: table-cell;
      font-size: 28px;
      font-weight: bold;
      text-align: center;
      vertical-align: middle;
    }
    &.hover {
      color: #098ddf;
      border: 2px dashed #098ddf;
    }
  }
  &.dragover .note-dropzone {
    display: table;
  }
  .note-toolbar {
    background-color: #f5f5f5;
    border-bottom: 1px solid #a9a9a9;
  }
  &.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    .note-editable {
      background-color: white;
    }
    .note-resizebar {
      display: none;
    }
  }
  &.codeview {
    .note-editable {
      display: none;
    }
    .note-codable {
      display: block;
    }
  }
  .note-statusbar {
    background-color: whitesmoke;
    .note-resizebar {
      width: 100%;
      height: 8px;
      cursor: ns-resize;
      border-top: 1px solid #a9a9a9;
      .note-icon-bar {
        width: 20px;
        margin: 1px auto;
        border-top: 1px solid #a9a9a9;
      }
    }
  }
  .note-editable {
    padding: 10px;
    overflow: auto;
    outline: 0;
    &[contenteditable="false"] {
      background-color: #e5e5e5;
    }
  }
  .note-codable {
    display: none;
    width: 100%;
    padding: 10px;
    margin-bottom: 0;
    font-family: Menlo, Monaco, monospace, sans-serif;
    font-size: 14px;
    color: #ccc;
    background-color: #222;
    border: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    box-shadow: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    resize: none;
  }
}

.note-air-editor {
  outline: 0;
}

.note-popover .popover {
  max-width: none;
  .popover-content a {
    display: inline-block;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
  }
  .arrow {
    left: 20px;
  }
  .popover-content {
    padding: 0 0 5px 5px;
    margin: 0;
  }
}

.note-toolbar {
  padding: 0 0 5px 5px;
  margin: 0;
}

.note-popover .popover .popover-content > .btn-group, .note-toolbar > .btn-group {
  margin-top: 5px;
  margin-right: 5px;
  margin-left: 0;
}

.note-popover .popover .popover-content .note-table .dropdown-menu, .note-toolbar .note-table .dropdown-menu {
  min-width: 0;
  padding: 5px;
}

.note-popover .popover .popover-content .note-table .dropdown-menu .note-dimension-picker, .note-toolbar .note-table .dropdown-menu .note-dimension-picker {
  font-size: 18px;
}

.note-popover .popover .popover-content .note-table .dropdown-menu .note-dimension-picker .note-dimension-picker-mousecatcher, .note-toolbar .note-table .dropdown-menu .note-dimension-picker .note-dimension-picker-mousecatcher {
  position: absolute !important;
  z-index: 3;
  width: 10em;
  height: 10em;
  cursor: pointer;
}

.note-popover .popover .popover-content .note-table .dropdown-menu .note-dimension-picker .note-dimension-picker-unhighlighted, .note-toolbar .note-table .dropdown-menu .note-dimension-picker .note-dimension-picker-unhighlighted {
  position: relative !important;
  z-index: 1;
  width: 5em;
  height: 5em;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIj4+Pjp6ekKlAqjAAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKhmnaJzPAAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC') repeat;
}

.note-popover .popover .popover-content .note-table .dropdown-menu .note-dimension-picker .note-dimension-picker-highlighted, .note-toolbar .note-table .dropdown-menu .note-dimension-picker .note-dimension-picker-highlighted {
  position: absolute !important;
  z-index: 2;
  width: 1em;
  height: 1em;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIjd6vvD2f9LKLW+AAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKwNDEVT0AAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC') repeat;
}

.note-popover .popover .popover-content .note-style h1, .note-toolbar .note-style h1, .note-popover .popover .popover-content .note-style h2, .note-toolbar .note-style h2, .note-popover .popover .popover-content .note-style h3, .note-toolbar .note-style h3, .note-popover .popover .popover-content .note-style h4, .note-toolbar .note-style h4, .note-popover .popover .popover-content .note-style h5, .note-toolbar .note-style h5, .note-popover .popover .popover-content .note-style h6, .note-toolbar .note-style h6, .note-popover .popover .popover-content .note-style blockquote, .note-toolbar .note-style blockquote {
  margin: 0;
}

.note-popover .popover .popover-content .note-color .dropdown-toggle, .note-toolbar .note-color .dropdown-toggle {
  width: 20px;
  padding-left: 5px;
}

.note-popover .popover .popover-content .note-color .dropdown-menu, .note-toolbar .note-color .dropdown-menu {
  min-width: 340px;
}

.note-popover .popover .popover-content .note-color .dropdown-menu .btn-group, .note-toolbar .note-color .dropdown-menu .btn-group {
  margin: 0;
}

.note-popover .popover .popover-content .note-color .dropdown-menu .btn-group:first-child, .note-toolbar .note-color .dropdown-menu .btn-group:first-child {
  margin: 0 5px;
}

.note-popover .popover .popover-content .note-color .dropdown-menu .btn-group .note-palette-title, .note-toolbar .note-color .dropdown-menu .btn-group .note-palette-title {
  margin: 2px 7px;
  font-size: 12px;
  text-align: center;
  border-bottom: 1px solid #eeeeee;
}

.note-popover .popover .popover-content .note-color .dropdown-menu .btn-group .note-color-reset, .note-toolbar .note-color .dropdown-menu .btn-group .note-color-reset {
  padding: 0 3px;
  margin: 3px;
  font-size: 11px;
  cursor: pointer;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.note-popover .popover .popover-content .note-color .dropdown-menu .btn-group .note-color-row, .note-toolbar .note-color .dropdown-menu .btn-group .note-color-row {
  height: 20px;
}

.note-popover .popover .popover-content .note-color .dropdown-menu .btn-group .note-color-reset:hover, .note-toolbar .note-color .dropdown-menu .btn-group .note-color-reset:hover {
  background: #eeeeee;
}

.note-popover .popover .popover-content .note-para .dropdown-menu, .note-toolbar .note-para .dropdown-menu {
  min-width: 216px;
  padding: 5px;
}

.note-popover .popover .popover-content .note-para .dropdown-menu > div:first-child, .note-toolbar .note-para .dropdown-menu > div:first-child {
  margin-right: 5px;
}

.note-popover .popover .popover-content .dropdown-menu, .note-toolbar .dropdown-menu {
  min-width: 90px;
}

.note-popover .popover .popover-content .dropdown-menu.right, .note-toolbar .dropdown-menu.right {
  right: 0;
  left: auto;
}

.note-popover .popover .popover-content .dropdown-menu.right::before, .note-toolbar .dropdown-menu.right::before {
  right: 9px;
  left: auto !important;
}

.note-popover .popover .popover-content .dropdown-menu.right::after, .note-toolbar .dropdown-menu.right::after {
  right: 10px;
  left: auto !important;
}

.note-popover .popover .popover-content .dropdown-menu li a i, .note-toolbar .dropdown-menu li a i {
  color: deepskyblue;
  visibility: hidden;
}

.note-popover .popover .popover-content .dropdown-menu li a.checked i, .note-toolbar .dropdown-menu li a.checked i {
  visibility: visible;
}

.note-popover .popover .popover-content .note-fontsize-10, .note-toolbar .note-fontsize-10 {
  font-size: 10px;
}

.note-popover .popover .popover-content .note-color-palette, .note-toolbar .note-color-palette {
  line-height: 1;
}

.note-popover .popover .popover-content .note-color-palette div .note-color-btn, .note-toolbar .note-color-palette div .note-color-btn {
  width: 20px;
  height: 20px;
  padding: 0;
  margin: 0;
  border: 1px solid white;
}

.note-popover .popover .popover-content .note-color-palette div .note-color-btn:hover, .note-toolbar .note-color-palette div .note-color-btn:hover {
  border: 1px solid black;
}

.note-dialog {
  > div {
    display: none;
  }
  .note-image-dialog .note-dropzone {
    min-height: 100px;
    margin-bottom: 10px;
    font-size: 30px;
    line-height: 4;
    color: lightgray;
    text-align: center;
    border: 4px dashed lightgrey;
  }
  .note-help-dialog {
    font-size: 12px;
    color: #ccc;
    background: transparent;
    background-color: #222 !important;
    border: 0;
    -webkit-opacity: .9;
    -khtml-opacity: .9;
    -moz-opacity: .9;
    opacity: .9;
    -ms-filter: alpha(opacity = 90);
    filter: alpha(opacity = 90);
    .modal-content {
      background: transparent;
      border: 1px solid white;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
    a {
      font-size: 12px;
      color: white;
    }
    .title {
      padding-bottom: 5px;
      font-size: 14px;
      font-weight: bold;
      color: white;
      border-bottom: white 1px solid;
    }
    .modal-close {
      font-size: 14px;
      color: #dd0;
      cursor: pointer;
    }
    .note-shortcut-layout {
      width: 100%;
      td {
        vertical-align: top;
      }
    }
    .note-shortcut {
      margin-top: 8px;
      th {
        font-size: 13px;
        color: #dd0;
        text-align: left;
      }
      td:first-child {
        min-width: 110px;
        padding-right: 10px;
        font-family: "Courier New";
        color: #dd0;
        text-align: right;
      }
    }
  }
}

.note-handle .note-control-selection {
  position: absolute;
  display: none;
  border: 1px solid black;
  > div {
    position: absolute;
  }
  .note-control-selection-bg {
    width: 100%;
    height: 100%;
    background-color: black;
    -webkit-opacity: .3;
    -khtml-opacity: .3;
    -moz-opacity: .3;
    opacity: .3;
    -ms-filter: alpha(opacity = 30);
    filter: alpha(opacity = 30);
  }
  .note-control-handle, .note-control-holder {
    width: 7px;
    height: 7px;
    border: 1px solid black;
  }
  .note-control-sizing {
    width: 7px;
    height: 7px;
    background-color: white;
    border: 1px solid black;
  }
  .note-control-nw {
    top: -5px;
    left: -5px;
    border-right: 0;
    border-bottom: 0;
  }
  .note-control-ne {
    top: -5px;
    right: -5px;
    border-bottom: 0;
    border-left: none;
  }
  .note-control-sw {
    bottom: -5px;
    left: -5px;
    border-top: 0;
    border-right: 0;
  }
  .note-control-se {
    right: -5px;
    bottom: -5px;
    cursor: se-resize;
  }
  .note-control-selection-info {
    right: 0;
    bottom: 0;
    padding: 5px;
    margin: 5px;
    font-size: 12px;
    color: white;
    background-color: black;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-opacity: .7;
    -khtml-opacity: .7;
    -moz-opacity: .7;
    opacity: .7;
    -ms-filter: alpha(opacity = 70);
    filter: alpha(opacity = 70);
  }
}
  

/* adjustment */

.note-editor .note-editable { background-color: rgba(48, 126, 204, 0.05); }
.note-editor .note-editable:focus { background-color: #fff; }
.note-editor .note-statusbar .note-resizebar { border-top: 1px solid #DBDBDB; }
.note-editor .note-statusbar:hover { background: #EEE; }
.note-editor .note-statusbar:active { background: #eaeaea; }
